<template>
  <div class="page__wrapper">
    <ag-grid
      ref="agGridDemo"
      pagination
      style="width:100%;height:100%;"
      edit-type="fullRow"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
      @gridReady="onGridReady"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            type="primary"
            @click="handleExportClick"
          >
            {{ $t('operation.export') }}
          </el-button>
          <el-button
            type="primary"
            @click="handleAddClick"
          >
            {{ $t('operation.create') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
  </div>
</template>

<script>
import Vue from 'vue'

const BASEURL = {
  list: '/system/user/list',
  delete: '/system/user/delete',
  office: '/system/office/list'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleSaveClick', 'handleDeleteClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" content="保存">
        <el-button type="text" icon="el-icon-circle-check" @click="handleSaveClick(params)" />
      </el-tooltip>
      <el-tooltip effect="light" content="取消">
        <el-button type="text" icon="el-icon-refresh-left" @click="handleDataSearch"/>
      </el-tooltip>
      <el-tooltip effect="light" content="删除">
        <el-button type="text" icon="el-icon-delete" @click="handleDeleteClick" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'SystemDemo',
  data () {
    return {
      searchModel: {},
      tableData: [],
      gridOptions: {},
      companyList: [],
      companyLoadingFlag: false,
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleSaveClick: this.handleSaveClick,
      handleDeleteClick: this.handleDeleteClick
    }
  },
  computed: {
    searchFormField () {
      return [
        { prop: 'loginName', label: this.$t('system_user.loginName'), type: 'Input', component: { clearable: true } },
        { prop: 'name', label: this.$t('system_user.name'), type: 'Input', component: { clearable: true } },
        { slotName: 'tableOperation', col: { xs: 12, sm: 16, md: 12, style: { textAlign: 'right' } }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          width: 40,
          pinned: 'left',
          suppressSizeToFit: true, // 本列宽度不参与自适应
          checkboxSelection: true
        },
        {
          headerName: '用户账户',
          field: 'loginName',
          editable: true, // 可编辑
          filter: 'agTextColumnFilter', // 筛选功能
          filterParams: { applyButton: true, resetButton: true },
          cellEditorParams: { // 自定义属性
            rules: { required: true }
          },
          minWidth: 200
        },
        {
          headerName: '用户名称',
          field: 'name',
          editable: (params) => params.data.status !== 'N', // 基于数据渲染
          cellEditorParams: {
            rules: [ // 校验
              { required: true },
              {
                validator (rule, value, callback, source, options) {
                  const errors = []
                  if (!/^[a-z0-9]+$/.test(value)) {
                    errors.push(new Error('用户名称只能包含数字和字母'))
                  }
                  callback(errors)
                }
              }
            ]
          },
          minWidth: 200
        },
        {
          headerName: '数字',
          field: 'num',
          type: 'NumberInput', // 数字输入框
          editable: true,
          cellEditorParams: {
            rules: { type: 'number', min: 0, max: 120 }
          },
          minWidth: 120
        },
        {
          headerName: '公司名称',
          // colId: 'company', // 如有两个以上的列 field 属性一样，建议定义 colId
          field: 'company',
          editable: true,
          type: 'Select', // 主数据下拉
          cellEditorParams: {
            searchable: true,
            componentProp: { // ele ui 属性
              remoteMethod: this.getCompanyList,
              loading: this.companyLoadingFlag,
              optionList: this.companyList,
              valueKey: 'id'
            },
            event: {
              focus: () => this.getCompanyList()
            }
          },
          valueFormatter: params => params.data.company && params.data.company.name,
          minWidth: 200
        },
        // {
        //   headerName: '手机号码',
        //   field: 'phone',
        //   editable: true,
        //   cellEditorParams: {
        //     rules: { len: 11 }
        //   },
        //   minWidth: 200
        // },
        // {
        //   headerName: this.$t('dealer_invoice.period'), // 日期类型 value formatter 写法备份
        //   field: 'period',
        //   editable: params => params.data.operationRole !== 'tt_operation_role_1',
        //   type: 'DatePicker',
        //   cellEditorParams: {
        //     componentProp: { type: 'month', clearable: true },
        //     rules: { required: true, message: this.$t('validate.isRequired') }
        //   },
        //   valueGetter: params => params.data.period ? this.$moment(params.data.period, 'YYYYMM') : null,
        //   valueSetter: params => {
        //     params.data.period = params.newValue ? this.$moment(params.newValue).format('YYYYMM') : ''
        //     return true
        //   },
        //   valueFormatter: params => params.value ? this.$moment(params.value, 'YYYYMM').format('YYYY-MM') : '',
        //   minWidth: 140
        // },
        {
          headerName: '修改日期',
          field: 'updateDate',
          editable: true,
          sortable: true,
          type: 'DatePicker', // 日期
          cellEditorParams: {
            componentProp: {
              type: 'datetime'
            }
          },
          valueFormatter: params => this.$moment(params.value).format('YYYY-MM-DD HH:mm:ss'),
          minWidth: 220
        },
        {
          headerName: '多级表头示例',
          children: [
            {
              headerName: '状态',
              field: 'status',
              editable: true,
              type: 'Select', // 下拉
              cellEditorParams: {
                componentProp: {
                  optionList: this.$getDictList('data_status').map(item => {
                    return { value: item.value, label: item.label }
                  })
                }
              },
              valueFormatter: params => { // 翻译
                return this.$getDictLabel({ type: 'data_status', value: params.value })
              },
              minWidth: 120
            },
            {
              headerName: '备注',
              field: 'remarks',
              editable: true,
              minWidth: 300
            }
          ]
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 100,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.gridOptions.suppressCsvExport = false
    this.gridOptions.rowModelType = 'serverSide'
    this.gridOptions.cacheBlockSize = 10
    this.gridOptions.paginationPageSize = 10
    this.gridOptions.paginationAutoPageSize = false
    this.frameworkComponents = { Operation: Operation }
    this.getCompanyList()
  },
  mounted () {
    // this.handleDataSearch()
  },
  methods: {
    onGridReady (params) {
      const datasource = this.createDatasource(this.server())
      params.api.setServerSideDatasource(datasource)
    },
    // getLastRowIndex (request, results) {
    //   if (!results || results.length === 0) {
    //     return request.startRow
    //   }
    //   // const currentLastRow = request.startRow + results.length
    //   // return currentLastRow <= request.endRow ? currentLastRow : -1
    //   return this.count
    // },
    server () {
      return {
        getData: (request, params) => {
          const paramsData = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
          const blockSize = request.endRow - request.startRow
          paramsData.page = {
            pageSize: blockSize,
            pageNo: request.startRow / blockSize + 1
          }
          this.$axios
            .post(BASEURL.list, paramsData)
            .then(resp => {
              const respData = resp.data
              // let lastRow = 0
              // lastRow = respData.countcount
              params.successCallback(respData.list, respData.countcount)
            })
            .catch(() => {
              params.failCallback()
            })
            .finally(() => {})
        }
      }
    },
    createDatasource (server) {
      return {
        // 获取更多行数据时触发 getRows
        getRows: params => {
          console.log('[Datasource] - rows requested by grid: ', params.request)
          // 从服务器获取数据给 request
          server.getData(params.request, params)
          // if (response.success) {
          //   // 将请求到的行数据提供给表格
          //   params.successCallback(response.rows, response.lastRow)
          // } else {
          //   // 通知表格请求失败
          //   params.failCallback()
          // }
        }
      }
    },
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
          this.tableData[0].num = 10.08
          this.tableData[1].num = 200
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleAddClick () {
      // this.tableData.splice(0, 0, {})
      this.tableData.push({})
      this.$nextTick(() => {
        this.$refs.agGridDemo.focusOnCell(this.tableData.length - 1, 'name')
      })
    },
    handleDeleteClick () {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.$message({ type: 'success', message: '删除成功（模拟）' })
            done()
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    },
    handleSaveClick (row) {
      this.$refs.agGridDemo.validate(row, status => {
        if (status) {
          console.log('save data:', row.data)
          this.$message({ type: 'success', message: '保存成功（模拟）' })
          this.handleDataSearch()
        }
      })
    },
    getCompanyList (name) {
      this.companyLoadingFlag = true
      this.$axios
        .post('/system/office/list', { pageSize: 20, name: name })
        .then(resp => {
          const respData = resp.data
          this.companyList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.companyLoadingFlag = false
        })
    },
    handleExportClick () {
      this.gridOptions.api.exportDataAsCsv()
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
